<template>
  <div class="hello" style="position: relative;">
    <div class="main-opt-platform">
      <div class="text-center main-box">
        <h3 class="text-grey">
          Please enter the One-Time Password to verify your account
        </h3>
        <p class="text-grey">
          A One-Time Password has been sent to +{{ user.phoneCountryCode + ` ${user.phoneNumber}` }} <span
            style="color:blue;font-size: 15px;cursor: pointer;" @click="toogleEditPhoneModal(true)">Edit</span>
        </p>

        <div class="mb-4">
          <input v-for="(input, index) in inputs" :key="index" ref="inputs" v-model="input.value" type="number"
            name="code" class="form-input code-input" maxlength="1" @input="handleInput(index, $event)"
            @keydown="handleKeyDown(index, $event)" required />
          <div v-if="requirederror">
            <span class="otp-req">OTP is Required</span>
          </div>
        </div>
        <button :disabled="verifyDisabled" type="submit" class="btn btn-validate" @click="verifyCodeFn()">
          Validate
        </button>
        <div class="mt-4">
          <h5 class="text-grey">
            If you have any problem with the OTP , click here to resend the otp <span @click="resendOtp(verifyDisabled)"
              style="color : blue; cursor:pointer;font-size: 15px">Resend OTP</span>
          </h5>
          <!-- <a href="#" target="_blank" class="text-grey">Entered a wrong number?</a> -->
        </div>
      </div>
    </div>
    <!-- Edit Phone Number Modal View -->
    <div v-if="editPhone" class="modal-overlay edit-phone-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h4>Edit your phone number</h4>
          <span @click="toogleEditPhoneModal(false)" class="modal-close">Cancel</span>
        </div>
        <div class="modal-body">
          <div class="edit-phone-numer">
            <div class="signup">
              <form @submit.prevent="changePhoneNumber(verifyDisabled)">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Mobile Phone Number</label>
                      <div class="row">
                        <div class="col-md-3">
                          <vue-country-code class="form-control" type="number" name="phoneCountryCode"
                            :enabledFlags="false" :enabledCountryCode="true" :ignoredCountries="['VN']"
                             @onSelect="onSelect"
                            :preferredCountries="['GB', 'FR', 'US']">
                          </vue-country-code>
                        </div>
                        <div class="col-md-9">
                          <input maxlength="17" type="number" v-model="changedCreds.phoneNumber" name="phoneNumber"
                            class="form-control" placeholder="Enter your mobile Phone number" id="phoneNumber" :class="{
            'is-invalid':
              submitted && $v.changedCreds.phoneNumber.$error,
          }" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Error message -->
                  <div v-if="submitted && $v.changedCreds.phoneNumber.$error" class="invalid-error">
                    <span>Please enter a phone number</span>
                  </div>
                </div>

                <div class="text-center sign-btn mt-4">
                  <button type="submit" class="btn secondary-btn btn-block">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {
  PHONE_NUMBER,
  VERIFY_PHONE_OTP,
  COUNTRY_CODE,
  GET_USER_API_ID,
  CREATE_LIST_API,
  FIRST_TIME,
  RESEND_OTP,
  EDIT_PHONENUMBER,
  USER_EMAIL
} from "../../store/constant/actions.type";
import { loadingService } from "../../main/common/services/loading.service";
import { required } from "vuelidate/lib/validators";



export default {
  name: "verifyOpt",
  data() {
    return {
      inputs: [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
      phoneNumber: null,
      verifyDisabled: false,
      requirederror: false,
      countryCode: null,
      editPhone: false,
      submitted: false,
      changedCreds: {
        phoneNumber: '',
        phoneCountryCode: ''
      },
      user: {
        phoneNumber: localStorage.getItem(PHONE_NUMBER),
        phoneCountryCode: localStorage.getItem(COUNTRY_CODE),
        email: localStorage.getItem(USER_EMAIL)
      },
    };
  },
  validations: {
    changedCreds: {
      phoneNumber: { required },
    },
  },
  methods: {
    onSelect({ dialCode }) {
      this.changedCreds.phoneCountryCode = dialCode;
    },
    resendOtp(isProcessing) {
      if (isProcessing) {
        return
      }
      loadingService.setloader(true);
      this.verifyDisabled = true
      //---Triggring the otp
      this.$store.dispatch(RESEND_OTP, this.user.phoneCountryCode + this.user.phoneNumber).then((res) => {
        this.flashMessage.success({
          title: "Success",
          message: res.message ?? 'Otp resent successfully!',
        });
      }).catch((err) => {
        this.flashMessage.error({
          title: "Error Message",
          message: err.response.data.message ?? 'Something went wrong!',
        });
      }).finally(() => {
        this.verifyDisabled = false
        loadingService.setloader(false)
      })
    },
    toogleEditPhoneModal(state) {
      this.changedCreds.phoneNumber = this.user.phoneNumber
      this.changedCreds.phoneCountryCode = this.user.phoneCountryCode
      this.submitted = false
      this.editPhone = state
    },
    changePhoneNumber(isProcessing) {
      this.submitted = true
      this.$v.$touch();
      if (this.$v.$invalid || isProcessing) { return }
      loadingService.setloader(true);
      this.$store.dispatch(EDIT_PHONENUMBER, { email: this.user.email, phone: this.changedCreds.phoneNumber, phoneCountryCode: this.changedCreds.phoneCountryCode }).then((res) => {
        //---Triggring the otp
        this.$store.dispatch(RESEND_OTP, res.phoneCountryCode + res.phone).then((resOtp) => {
          //--Toaster message
          this.flashMessage.success({
            title: "Success",
            message: resOtp.message ?? 'Otp resent successfully!',
          });
          //---Setting local variables
          this.user.phoneNumber = res.phone
          this.user.phoneCountryCode = res.phoneCountryCode
          //--Setting the credentials in local storage
          localStorage.setItem(PHONE_NUMBER, res.phone)
          localStorage.setItem(COUNTRY_CODE, res.phoneCountryCode)
          //---Hiding the modal
          this.editPhone = false
        }).catch((err) => {
          //--Handling otp trigger error
          this.flashMessage.error({
            title: "Error Message",
            message: err.response.data.message ?? 'Something went wrong!',
          });
        })
      }).catch((err) => {
        //---Handling update phone number error
        this.flashMessage.error({
          title: "Error Message",
          message: err.response.data.message ?? 'Something went wrong!',
        });
      }).finally(() => {
        this.verifyDisabled = false
        loadingService.setloader(false);
      })
    },
    verifyCodeFn() {
      let myOtp =
        this.inputs[0].value +
        this.inputs[1].value +
        this.inputs[2].value +
        this.inputs[3].value +
        this.inputs[4].value +
        this.inputs[5].value;

      let postDataForOtp = {
        toPhoneNumber: this.user.phoneCountryCode + this.user.phoneNumber,
        otp: myOtp,
      };
      if (
        this.inputs[0].value != "" &&
        this.inputs[1].value != "" &&
        this.inputs[2].value != "" &&
        this.inputs[3].value != "" &&
        this.inputs[4].value != "" &&
        this.inputs[5].value != ""
      ) {
        this.verifyDisabled = true;
        this.$store
          .dispatch(VERIFY_PHONE_OTP, postDataForOtp)
          .then((response) => {
            if (response.valid) {
              this.$router.push({ name: "signin" });
              localStorage.setItem(FIRST_TIME, response.valid);
            } else {
              this.inputs[0].value = "";
              this.inputs[1].value = "";
              this.inputs[2].value = "";
              this.inputs[3].value = "";
              this.inputs[4].value = "";
              this.inputs[5].value = "";
              this.verifyDisabled = false;
              this.flashMessage.error({
                message: "Incorrect OTP",
              });
            }
          })
          .catch((err) => {
            this.verifyDisabled = false;
            this.inputs[0].value = "";
            this.inputs[1].value = "";
            this.inputs[2].value = "";
            this.inputs[3].value = "";
            this.inputs[4].value = "";
            this.inputs[5].value = "";
            this.flashMessage.error({
              title: "Error Message",
              message: err.response.data?.message,
            });
          });
      } else {
        this.requirederror = true;
      }
    },
    handleInput(index, event) {
      let value = event.target.value;
      this.inputs[0].value.length > 1
        ? (this.inputs[0].value = this.inputs[0].value.slice(0, 1))
        : this.inputs[2].value.length > 1
          ? (this.inputs[2].value = this.inputs[2].value.slice(0, 1))
          : this.inputs[3].value.length > 1
            ? (this.inputs[3].value = this.inputs[3].value.slice(0, 1))
            : this.inputs[4].value.length > 1
              ? (this.inputs[4].value = this.inputs[4].value.slice(0, 1))
              : this.inputs[5].value.length > 1
                ? (this.inputs[5].value = this.inputs[5].value.slice(0, 1))
                : "";
      // If the user deletes a single number, move the focus to the previous input field
      if (value === "" && event.inputType === "deleteContentBackward") {
        const prevInput = this.$refs.inputs[index - 1];
        if (prevInput) {
          prevInput.focus();
        }
      } else if (event.inputType != "deleteContentBackward" && index < 5) {
        const nextInput = this.$refs.inputs[index + 1];
        if (nextInput) {
          nextInput.focus();
        }
      } else if (index == 5) {
        if (value.length > 1) {
          this.inputs[5].value = this.inputs[5].value.slice(0, 1);
        }
      }
    },
    handleKeyDown(index, event) {
      if (event.keyCode === 8 && event.target.value === "") {
        const prevInput = this.$refs.inputs[index - 1];
        if (prevInput) {
          prevInput.focus();
        }
      }
    },
    getUserApiId(email) {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_USER_API_ID)
        .then((data) => {
          if (data) {
            localStorage.setItem("$s", data.apiId);
            loadingService.setloader(false);
            this.createDefultList(data.apiId, email);
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          console.log(err);
        });
    },
    createDefultList(apiId, email) {
      let payload = {
        apiId: apiId,
        user: email,
        listName: "Default",
      };
      loadingService.setloader(true);
      this.$store
        .dispatch(CREATE_LIST_API, payload)
        .then((response) => {
          if (response) {
            console.log("res ", response);
          }
          loadingService.setloader(false);
        })
        .catch((err) => {
          loadingService.setloader(false);
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.main-opt-platform {
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.main-box {
  background: #f7f8fa;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid #dfe1e6;
  width: 50%;
  margin: auto;
}

input {
  border: none;
  border-bottom: 4px solid #ddd7d7;
  max-width: 60px;
  margin-right: 35px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  background-color: transparent;
}

input:focus {
  outline: none;
  border-color: #bbbbbb;
}

.text-grey {
  color: #64676a;
}

p {
  font-weight: 500;
  font-size: 18px;
}

.btn-validate {
  background-color: #2589ff;
  border-color: #2589ff;
  padding: 16px;
  min-width: 260px;
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.4px;
  border-radius: 10px;
  transition: 0.6s all;
}

.btn-validate:hover {
  background-color: transparent;
  color: #2589ff;
  transition: 0.6s all;
}

a:hover {
  color: #2589ff;
}

span.otp-req {
  font-size: 17px;
  font-weight: 700;
  color: #dc3545;
  display: block;
  padding-top: 28px;
}

.modal-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  height: auto;
  width: 90%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-close {
  cursor: pointer;
  color: #007bff;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-input {
  height: 40px;
  width: 100% !important;
  padding: 0 0px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-button {
  height: 40px;
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-button:hover {
  background-color: #0056b3;
}

.edit-phone-numer {
  width: 100%;
  padding-bottom: 10px;
}

.edit-phone-numer input {
  width: 100%;
  max-width: 100%;
  font-size: 13px;
  border: 1px solid #E0E0E0;
  background: #FAFAFA;
  margin: 0;
  text-align: left;
}

.edit-phone-wrapper .modal-container {
  max-width: 520px;
}

.edit-phone-numer .col-md-9,
.edit-phone-numer .col-md-3 {
  padding: 0;
}

.edit-phone-numer .row {
  margin: 0;
}

.edit-phone-numer .vue-country-select {
  padding: 0;
}

.invalid-error {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
